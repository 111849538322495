import React from 'react';
import InputLabel from "@mui/material/InputLabel"
import { useField, useFormikContext } from "formik"
import Select2 from "react-select"
import AsyncSelect from "react-select/async"
import FormGroup from "@mui/material/FormGroup"

const FormikMultiSelect = ({ statement }) => {
    const [field, meta, helpers] = useField(statement.name);
    const form = useFormikContext();

    return (
        <FormGroup>
            {statement.label && (
                <InputLabel htmlFor={statement.name}>
                    {statement.label}
                </InputLabel>
            )}
            {!statement.async ? (
                <Select2
                    value={field.value}
                    className="multi-select-input"
                    classNamePrefix="multi-select"
                    options={statement.options}
                    isMulti
                    onChange={(value) => {
                        helpers.setValue(value);
                    }}
                    defaultValue={statement.defaultValue}
                    placeholder={statement.placeholder}
                    {...statement.params}
                />
            ) : (
                <AsyncSelect
                    value={field.value}
                    className="multi-select-input"
                    classNamePrefix="multi-select"
                    defaultOptions
                    loadOptions={async inputValue => {
                        try {
                            const loadedOptions = await statement.getOptions(inputValue);

                            return loadedOptions;
                        } catch (err) {
                            return [];
                        }
                    }}
                    isMulti
                    onChange={(value) => {
                        form.setFieldValue(field.name, value);
                    }}
                    defaultValue={statement.defaultValue}
                    placeholder={statement.placeholder}
                    {...statement.params}
                />
            )}
        </FormGroup>
    );
};

export default FormikMultiSelect;
